var amountSlider = $("#amountSlider").slider({
    value: 5000,
    step: 1000,
    min: 5000,
    max: 60000,
});

var duesSlider = $("#duesSlider").slider({
    value: 6,
    step: 1,
    min: 1,
    max: 12,
});

amountSlider.on('slide', updateSelectedSliderValue);
duesSlider.on('slide', updateSelectedSliderValue);

function updateSelectedSliderValue() {
    let capital = amountSlider.slider('getValue');
    let dues = duesSlider.slider('getValue');
    
    capital = new Intl.NumberFormat('de-DE').format(capital)
    $("#moneySelected").html("$" + capital);

    if (dues == 1) {
        dues = "1 cuota";
    } else {
        dues = dues + " cuotas";
    }
    $("#duesSelected").html(dues);
}

amountSlider.on('slideStop', getTotalCreditRequest);
duesSlider.on('slideStop', getTotalCreditRequest);

function getTotalCreditRequest() {
    let capital = amountSlider.slider('getValue');
    let dues = duesSlider.slider('getValue');
}